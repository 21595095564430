export const date = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31'
];

export const month = [
    {
        name: 'January',
        value: '01'
    },
    {
        name: 'February',
        value: '02'
    },
    {
        name: 'March',
        value: '03'
    },
    {
        name: 'April',
        value: '04'
    },
    {
        name: 'May',
        value: '05'
    },
    {
        name: 'June',
        value: '06'
    },
    {
        name: 'July',
        value: '07'
    },
    {
        name: 'August',
        value: '08'
    },
    {
        name: 'September',
        value: '09'
    },
    {
        name: 'October',
        value: '10'
    },
    {
        name: 'November',
        value: '11'
    },
    {
        name: 'December',
        value: '12'
    }
];
// age range for dropdown list (2021-1980)
export const year = [
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000',
    '1999',
    '1998',
    '1997',
    '1996',
    '1995',
    '1994',
    '1993',
    '1992',
    '1991',
    '1990',
    '1989',
    '1988',
    '1987',
    '1986',
    '1985',
    '1984',
    '1983',
    '1982',
    '1981',
    '1980'
];